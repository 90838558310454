@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  scrollbar-width: none;
  /* TODO: switch to rem for more responsiveness */
  /* font-size: 62.5%; */
}

html::-webkit-scrollbar {
  width: 0;
}

body {
  margin: 0;
  /* TODO: properly customize fonts and font-family styles */
  /* font-family: "Raleway", sans-serif; */
  font-family: "Catamaran", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #314352 !important;
}

.source-sans-3-font {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Source Sans 3", sans-serif !important;
}

*:focus {
  box-shadow: none !important;
}

*[data-focus] {
  box-shadow: none !important;
}

.text-center {
  font-family: inherit !important;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #537cd8;
  cursor: pointer;
  width: fit-content;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #537cd8;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.device-select-dropdown {
  /* font-size: 14px; */
  width: 100%;
}

.rmsc {
  --rmsc-main: #d5e3ee !important;
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: #e2e6ea !important;
  --rmsc-border: #d5e3ee !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 12px !important; /* Radius */
  --rmsc-border-bottom-left-radius: 0 !important;
  --rmsc-border-bottom-right-radius: 0 !important;
  --rmsc-h: 38px !important; /* Height */
}

.dropdown-content {
  margin-top: -5px;
}

.panel-content {
  border-radius: 12px !important;
}

/* .select-panel {
}

.select-panel .search {
} */

.select-panel input {
  background-color: #f7fafd !important;
  color: #314352;
  padding: 10px 20px !important;
}

.select-panel input::placeholder {
  display: none !important;
}

.select-panel ul.options {
  height: 200px;
}

.select-panel ul.options .no-options {
  display: none;
  margin: auto;
}

.dropdown-heading {
  padding: 35px 30px !important;
  /* display: none !important; */
}

@media (max-width: 30em) {
  .dropdown-heading {
    font-size: 15px;
    padding: 35px 10px !important;
    /* display: none !important; */
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-20px);
    opacity: 0;
    height: 0;
  }
}

/* @keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
} */

.phoneInput input {
  outline: none;
  background-color: transparent;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  justify-content: center !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  flex: none !important;
}
